<template>
    <div class="subdivision-page">
        <div class="item-page__header" v-if="loading">
            <v-skeleton-loader
                    type="list-item-avatar-three-line"
                    width="50%"
                    height="100%"
            />
        </div>
        <div class="item-page__body" v-if="loading">
            <v-skeleton-loader
                    type="list-item, list-item, list-item, list-item"
                    width="50%"
                    height="100%"
            />
            <v-skeleton-loader
                    type="list-item-avatar, list-item-avatar"
                    width="50%"
                    height="100%"
            />
        </div>
        <div class="subdivision-page__header" v-if="!loading">
            <img :src="require('@/assets/img/pages/subdivision.png')" alt="" class="mr-9">
            <div class="subdivision-page__name">
                <v-text-field
                        class="evi-text-field"
                        color="#44D370"
                        v-model="subdivision.name"
                        disabled
                >
                    <template v-slot:label>
                        Название подразделения
                    </template>
                </v-text-field>
                <div class="subdivision-page__actions  mt-1" v-if="hasRight('subdivisions.manage')">
                    <v-btn text class="evi-button-text" @click="removeSubdivisionModal = true" v-if="!isMainSub">
                        <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
                        Удалить
                    </v-btn>
                </div>
            </div>
            <div class="subdivision-page__close" @click="$router.go(-1)">
              <v-icon size="20">mdi-close</v-icon>
            </div>
        </div>
        <div class="subdivision-page__body" v-if="!loading">
            <div class="subdivision-page__edit">
                <v-text-field
                        class="evi-text-field mb-7"
                        color="#44D370"
                        v-model="name"
                        @change="editSubdivisionPage"
                        :disabled="!hasRight('subdivisions.manage')"
                        hide-details
                >
                    <template v-slot:label>
                        Название подразделения
                    </template>
                </v-text-field>
                <v-autocomplete
                        class="evi-autocomplete"
                        color="#44D370"
                        v-model="head"
                        :items="localUsers"
                        item-text="name"
                        item-value="id"
                        :disabled="isMainSub || !hasRight('subdivisions.manage')"
                        @change="editSubdivisionPage"
                        :loading="userLoading"
                        @focus="loadUsers"
                        :search-input.sync="userSearch"
                        @input.native="searchUser(userSearch)"
                        item-color="green"
                        hide-details
                >
                    <template v-slot:label>
                        Руководитель
                    </template>
                    <template v-slot:append-item>
                        <div v-intersect="userIntersect" />
                    </template>
                    <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
                    </template>
                </v-autocomplete>
            </div>
            <div class="subdivision-page__subdivisions">
                <div class="subdivision-page__headmaster mb-11">
                    <p>Руководитель</p>
                    <v-menu
                            top
                            offset-y
                            max-height="156"
                            open-on-hover
                            v-if="subdivision.head"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                                    color="#FFFFFF"
                                    size="53"
                                    class="subdivision-page__avatar"
                                    v-bind="attrs" v-on="on"
                                    @click="openUserPage(subdivision.head.id)"
                            >
                                <img
                                    :src="subdivision.head.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                                    alt=""
                                >
                            </v-avatar>
                        </template>
                        <v-list-item>{{subdivision.head.name}}</v-list-item>
                    </v-menu>
                </div>
                <div class="subdivision-page__subordinates">
                    <p>Сотрудники</p>
                    <div class="items">
                        <v-menu
                                top
                                offset-y
                                max-height="156"
                                open-on-hover
                                v-for="user in subdivision.users"
                                :key="user.id"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                        color="#FFFFFF"
                                        size="53"
                                        class="subdivision-page__avatar"
                                        v-bind="attrs" v-on="on"
                                        @click="openUserPage(user.id)"
                                >
                                    <img
                                        :src="user.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                                        alt=""
                                    >
                                </v-avatar>
                            </template>
                            <v-list-item>{{user.name}}</v-list-item>
                        </v-menu>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog
                v-model="removeSubdivisionModal"
                max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Удаление: {{subdivision.name}}
                </v-card-title>
                <v-card-text v-if="subdivision.users && subdivision.users.length === 0">Вы действительно хотите удалить подразделение?</v-card-text>
                <v-card-text v-else>Вы не можете удалить подразделение, пока в нем есть сотрудники.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            @click="removeSubdivisionModal = false"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="red"
                            text
                            @click="removeSubdivision"
                            v-if="subdivision.users && subdivision.users.length === 0"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import {hasRight} from '@/utils/access/hasRight';
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
    name: "SubdivisionPage",
    mixins: [OpenPagesMixin, UserAutocompleteRequestsMixin],
    data: () => ({
        head: '',
        name: '',
        removeSubdivisionModal: false,
    }),
    computed: {
        ...mapState("users", ['subdivision',]),
        ...mapState("common", ["loading"]),
        isMainSub () {
            return this.subdivision.id === 1;
        },
        localUsers () {
            if (this.users.length) {
                return this.users;
            }
            else if (this.subdivision.head) {
                return [this.subdivision.head];
            }
            return [];
        },
    },
    methods: {
        ...mapActions({
            getSubdivision: 'users/getSubdivision',
            editSubdivision: 'users/editSubdivision',
            deleteSubdivision: 'users/deleteSubdivision',
        }),
        hasRight: hasRight,
        getData() {
            let userId = this.$route.params.id;
            this.getSubdivision(userId);
        },
        editSubdivisionPage () {
            this.editSubdivision({
                name: this.name,
                head_user_id: this.head,
                id: this.subdivision.id,
            });
        },
        removeSubdivision () {
            this.deleteSubdivision(this.subdivision).then(() => {
                this.removeSubdivisionModal = false;
                this.$router.push({path: '/subdivisions'});
            })
        },
    },
    watch: {
        subdivision (val) {
            this.name = val.name;
            this.head = val.head.id;
        },
        '$route.params.id': {
            handler: function() {
                this.getData();
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style lang="scss" scoped>
    .subdivision-page {
        background: #FFFFFF;
        border-radius: 32px;
        min-height: 751px;

        &__header {
            width: 100%;
            padding: 56px 94px;
            height: 227px;
            display: flex;
            justify-content: space-between;
            background: #FFFFFF;
            box-shadow: 0 24px 48px rgba(30, 28, 46, 0.08);
            border-radius: 32px;

            .evi-button-text {
                padding: 0 !important;
                margin-left: 0 !important;
            }
        }

        &__close {
            cursor: pointer;
            border: 2px solid rgba(0, 0, 0, 0.38);
            border-radius: 50%;
            height: 35px;
            width: 35px;
            position: absolute;
            top: 30px;
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 11px;
                /*position: absolute;*/
                top: 10px;
                left: 10px;
            }
        }

        &__name {
            display: flex;
            width: 100%;
            flex-direction: column;
            position: relative;
            .evi-text-field  {
                width: 100%;
                margin-top: 16px;
            }
            .users__status {
                position: absolute;
                top: 80px;
                left: 90px;
                img {
                    width: 23px ;
                }
            }
        }

        &__body {
            padding: 0 100px;
            margin-top: 54px;
            display: flex;

            .evi-select {
                width: 262px !important;
            }

            .evi-text-field {
                width: 262px !important;
            }
        }

        .v-select__selection--comma {
            display: none !important;
        }

        &__edit {
            padding-right: 95px;
            margin-right: 97px;
            border-right: 1px solid #EBEBEC;
            padding-top: 40px;
        }

        &__subdivisions {
            padding-top: 40px;

            p {
                font-weight: normal;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.38) !important;;
            }
        }

        &__subordinates {
            .items {
                max-width: 330px;

                .subdivision-page__avatar {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
</style>
